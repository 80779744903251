<template>
  <v-app-bar
    app
    height="64px"
    flat
    style="
      height: auto;
      padding-top: env(safe-area-inset-top);
      background: transparent;
      top: 0;
      z-index: 100;
      background-color: var(--v-background-base);
    "
  >
    <v-row dense justify="center" align="center">
      <v-btn
        large
        fixed
        left
        icon
        style="margin-left: -12px; color: var(--v-onbackground-base)"
        @click="closeBar ? $emit('close') : $router.back()"
        ><v-icon style="margin-left: -4px; font-size: 42px"
          >mdi-chevron-left</v-icon
        ></v-btn
      >
      <div class="Heading-Dark-H4-18-Center" style="width: 100%">
        {{ $t(title) }}
      </div>
    </v-row>
  </v-app-bar>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    closeBar: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
