<template>
  <v-container class="pt-0">
    <back-toolbar :title="$t('dashboard.categories')"></back-toolbar>
    <v-row>
      <v-col
        cols="6"
        v-for="(item, index) in filteredCategories"
        :key="`category-${index}`"
      >
        <div>
          <v-card
            elevation="6"
            :img="item.image"
            class="mr-1 ml-1"
            :height="$vuetify.breakpoint.xs ? '18vh' : '20vh'"
            width="18vh"
            style="
              border-radius: 10px !important;
              margin-bottom: 5px;
              background-size: contain;
            "
            :style="
              $vuetify.breakpoint.xs
                ? 'margin-bottom:5px;'
                : 'margin-bottom:15px;'
            "
            @click="
              $router.safePush({
                name: 'byCategory',
                params: {
                  id: item.id,
                  type: 'category',
                },
              })
            "
          >
          </v-card>
          <div
            style="word-break: break-word; white-space: normal"
            class="Heading-Dark-H5-16-Right-Align ml-1 mr-1 pb-2 text-center"
          >
            {{ item.title }}
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BackToolbar from "@/components/app/common/BackToolbar";
import { mapGetters } from "vuex";
export default {
  components: {
    BackToolbar,
  },
  computed: {
    ...mapGetters("categories", ["filteredCategories"]),
  },
};
</script>
