var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-0"},[_c('back-toolbar',{attrs:{"title":_vm.$t('dashboard.categories')}}),_c('v-row',_vm._l((_vm.filteredCategories),function(item,index){return _c('v-col',{key:("category-" + index),attrs:{"cols":"6"}},[_c('div',[_c('v-card',{staticClass:"mr-1 ml-1",staticStyle:{"border-radius":"10px !important","margin-bottom":"5px","background-size":"contain"},style:(_vm.$vuetify.breakpoint.xs
              ? 'margin-bottom:5px;'
              : 'margin-bottom:15px;'),attrs:{"elevation":"6","img":item.image,"height":_vm.$vuetify.breakpoint.xs ? '18vh' : '20vh',"width":"18vh"},on:{"click":function($event){return _vm.$router.safePush({
              name: 'byCategory',
              params: {
                id: item.id,
                type: 'category',
              },
            })}}}),_c('div',{staticClass:"Heading-Dark-H5-16-Right-Align ml-1 mr-1 pb-2 text-center",staticStyle:{"word-break":"break-word","white-space":"normal"}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }